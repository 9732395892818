<template>
    <div :id = "id" ref="dropZone"  @dragenter="onEnter" @dragover="onOver" @drop.prevent="onDrop" @paste="onPaste" >
        <slot></slot>
        <Progress :fileUpload = "dataObject.fileUpload" :progress="progress" v-if=" !hideProgressBar" ref="progressModal"></Progress>   
        <div v-if="showDropZone" @dragleave="onLeave($event)" class="dropzone" :class="(!dataObject.allowInsert || !dataObject.allowUpdate) ? 'disabled' : ' '"></div> 
     </div>
</template>

<script setup>
import './DataObject.FileUpload.ts';
import { ref, useAttrs } from 'vue';
import Progress from './components.FileUploadProgress.vue';

function alert(...args) {
    import('o365-vue-services').then(services => services.alert(...args));
} 

const fileInput = ref(null);
const progressModal = ref(null);
const id = "_uploadDropZone_" + crypto.randomUUID();

const dropZone = ref(null);
const showDropZone = ref(false);

const props = defineProps({
    /** Dataobject that will be used when uploading files */
    dataObject: {
        required: true
    },
    /** Specifies if the FileUpload should disable the default upload button
     *  Only be used if no template is added to the default slot
     */

    beforeUpload: {
        type: Function,
        required: false,
        default: null
    },
    text: {
        type: String,
        required: false,
        default: "Upload"
    },

    hideProgressBar: {
        type: Boolean,
        required: false,
        default: false
    },
    primKey: {
        type: String,
        required: false,
        default: null
    },
    routeUrl: {
        type: String,
        required: false,
    },
    disableChunks: {
        type: Boolean,
        required: false,
        default: false
    },
    skipRecordCreate: {
        type: Boolean,
        default: false
    }
});

let classList = "btn btn-primary btn-sm";
const emit = defineEmits(['uploaded', 'error']);
const attrs = useAttrs();
const progress = ref({
    total: 0,
    progress: 0

})
if (attrs.hasOwnProperty("class")) {
    classList = attrs['class'];
}

const uploadCompleted = (...args) => {
    if (progressModal.value && progress.value && !progress.value.error) {
        setTimeout(() => {
            progressModal.value.modal.hide();
        }, 60)
    }
    emit('uploaded', ...args);
}
const uploadError = () => {

    setTimeout(() => {

        progressModal.value.modal.hide();
    }, 60)





    emit('error');
}

const fileUploadProgress = (pProgress) => {
    progress.value = pProgress;

    if (progressModal.value && !pProgress.completed) {

        progressModal.value.modal.show();
    }

}


const openFileUpload = () => {
    fileInput.value.click();
}
const fileInputChange = () => {
    const vOptions = getOptions();
    vOptions.files = fileInput.value.files;

    props.dataObject.fileUpload.upload(vOptions, props.primKey ? { PrimKey: props.primKey } : null);
}


const getOptions = () => {
    if (props.skipRecordCreate) {
        props.dataObject.fileUpload.skipRecordCreate = true;
    }
    if (props.routeUrl) {
        props.dataObject.fileUpload.url = props.routeUrl;
    }
    if (props.disableChunks) {
        props.dataObject.fileUpload.useChunks = false;
    }
    return {
        beforeUpload: props.beforeUpload,
        onProgress: fileUploadProgress,
        onCompleted: uploadCompleted,
        onError: uploadError
    }
}

const onDrop = (e) => {
    const skipEvent = e.dataTransfer?.types.includes('o365-nt/skip-upload');
    if (skipEvent) { return; }
    if (e.dataTransfer && e.dataTransfer.files.length && props.dataObject.allowInsert && props.dataObject.allowUpdate) {
        const vOptions = getOptions();
        e.dataTransfer.dropEffect = "copy";
        vOptions.files = e.dataTransfer.files;
        props.dataObject.fileUpload.upload(vOptions);
        showDropZone.value = false;
    }
    if (!props.dataObject.allowInsert || !props.dataObject.allowUpdate) {
        showDropZone.value = false;
    }
    e.preventDefault();
}
const onOver = (e) => {

    if (e.dataTransfer && e.dataTransfer.types.indexOf("Files") > -1) {
        showDropZone.value = true;
        e.dataTransfer.dropEffect = "copy";
    }
    e.preventDefault();
}
const onEnter = (e) => {

    if (e.dataTransfer && e.dataTransfer.files.length) {
        showDropZone.value = true;
    }
    e.preventDefault();
}
const onLeave = (e) => {
    showDropZone.value = false;
    e.preventDefault();
}

const onPaste = (e) => {
    const items = (e.clipboardData || e.originalEvent.clipboardData).items;
    if (items.length > 0 && items[0].kind === 'file' && props.dataObject.allowInsert && props.dataObject.allowUpdate) {
        //  var vFile = e.clipboardData.items[0].getAsFile();
        const vOptions = getOptions();
        vOptions.files = [e.clipboardData.items[0].getAsFile()];
        props.dataObject.fileUpload.upload(vOptions, props.primKey ? { PrimKey: props.primKey } : null);
        e.preventDefault();
    } else if (items.length > 0 && items[0].kind === 'string' && props.dataObject.allowInsert && props.dataObject.allowUpdate) {
        const string = e.clipboardData.getData('Text');
        if (!string.includes('base64')) return;
        const vOptions = getOptions();
        vOptions.files = [string];
        props.dataObject.fileUpload.upload(vOptions, props.primKey ? { PrimKey: props.primKey } : null);
        e.preventDefault();
    }
    if (!props.dataObject.allowInsert || !props.dataObject.allowUpdate) {
        alert(`Upload is disabled for ${props.dataObject.id}`)
    }
}
</script>

<style>
     .dropzone:after {
        content: "Drop files here...";
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        inset: 0;
        background-color: rgba(255, 255, 255, .8);
        border: 'dashed grey 4px';
        z-index: 1000;
    }

    .dropzone.disabled:after {
        content: "Upload is disabled...";
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        inset: 0;
        background-color: rgba(255, 255, 255, .8);
        border: 'dashed grey 4px';
        z-index: 1000;
    }
    
    .disabled {
        pointer-events: none;
    }
</style>